import Layout from '@/components/layout/Layout';
import { FC } from 'react';
import Link from 'next/link';
import SectionTitleMenu from '@/components/ui/section-title-menu/SectionTitleMenu';
import BookCardsHiddenList from '@/components/ui/book-cards-hidden-list/BookCardsHiddenList';
import ScrollCardsList from '@/components/ui/scroll-cards-list/ScrollCardsList';
import ScrollCardItem from '@/components/ui/scroll-card-item/ScrollCardItem';
import SectionTitle from '@/components/ui/section-title/SectionTitle';
import styles from './Error.module.scss';
import { IBookPage, IBooksPageData } from '@/interfaces/booksPage.interface';
import { IGenresData } from '@/interfaces/genres.interface';

interface IError {
  popularBooks: IBookPage;
  newBooks: IBookPage;
  menuGenres: IGenresData[];
}

const Error: FC<IError> = ({ popularBooks, newBooks, menuGenres }) => {
  //Seo block
  const SEO_TITLE = 'Страница не найдена – Ошибка 404';
  const SEO_DESCRIPTION = 'Запрошенной страницы нет на нашем сайте.Возможно, вы ввели неправильный адрес в строке браузера.';
  const SEO_CANONICAL = '/error';

  return (
    <Layout title={SEO_TITLE} description={SEO_DESCRIPTION} canonicalHref={SEO_CANONICAL} pageHref={SEO_CANONICAL} menuGenres={menuGenres} menuNew={newBooks}>
      <div className={styles.errorBlock}>
        <div className={styles.errorBlock__img}></div>
        <div className={styles.errorBlock__desk}>
          <SectionTitle name="Извините, такой страницы нет…" extraClassName="mainTitle_error" />
          <div className={styles.errorBlock__txt}>Попробуйте вернуться назад и повторить еще раз, или выберите из предложенного ниже.</div>
          <Link prefetch={false} href="/" className={styles.errorBlock__btn}>
            Перейти на главную
          </Link>
        </div>
      </div>
      <SectionTitleMenu name={'Популярные книги'} />
      <BookCardsHiddenList books={popularBooks.data} extraClassName="bookCardsList_mb" />
      <SectionTitle name={'Новинки'} />
      <ScrollCardsList>{newBooks.data.length ? newBooks.data.map((book: IBooksPageData) => <ScrollCardItem key={book.id} id={book.id} rating={book.rating} author={book.author} book={book.book} />) : <div>Книги не найдены</div>}</ScrollCardsList>
    </Layout>
  );
};

export default Error;
